import React from 'react';
import SVG from 'react-inlinesvg';
import * as styles from './Socials.module.scss';

import FacebookICon from '../../../images/facebook.svg';
import TikTokIcon from '../../../images/tiktok.svg';
import YoutubeIcon from '../../../images/youtube.svg';

const Socials = () => {
    return (
        <div className={`${styles.wrapper}`}>
            <a
                className={`${styles.button}`}
                href="https://www.facebook.com/agnieszka.kobylarz.bojanow/"
            >
                <SVG src={FacebookICon} />
            </a>
            <a
                className={`${styles.button}`}
                href="https://www.tiktok.com/@kobylarzagnieszka"
            >
                <SVG src={TikTokIcon} />
            </a>
            <a
                className={`${styles.button}`}
                href="https://www.youtube.com/@PoStronieMieszkancow"
            >
                <SVG src={YoutubeIcon} />
            </a>
        </div>
    );
};

export default Socials;
