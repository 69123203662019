import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import shareImage from '../../../images/icon.png';

import Socials from '../../atoms/Socials/Socials';

import '../../theme/sanitize.css';
import '../../theme/index.css';

const Layout = ({ location, children }) => {
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setPageLoaded(true);
        }, 500);
    });

    return (
        <>
            <Helmet htmlAttributes={{ lang: 'pl' }}>
                <title>Gmina dla Ciebie | Bojanów</title>
                <meta name="description" content="Gmina dla Ciebie | Bojanów" />
                <meta name="og:title" content="Gmina dla Ciebie" />
                <meta name="twitter:title" content="Gmina dla Ciebie" />
                <meta
                    name="og:description"
                    content="Gmina dla Ciebie | Bojanów"
                />
                <meta
                    name="twitter:description"
                    content="Gmina dla Ciebie | Bojanów"
                />
                <meta name="twitter:card" content="summary" />
                <meta
                    name="article:modified_time"
                    content="2023-11-01T09:00:00Z"
                />
                <meta name="og:locale" content="en_EN" />
                <meta name="og:type" content="article" />
                <meta name="og:site_name" content="gminadlaciebie.pl" />
                <meta property="og:url" content="https://gminadlaciebie.pl/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Gmina dla Ciebie" />
                <meta
                    property="og:description"
                    content="Gmina dla Ciebie | Bojanów"
                />
                <meta name="og:image" content={shareImage} />
                <meta name="og:image:width" content="1200" />
                <meta name="og:image:height" content="630" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    property="twitter:url"
                    content="https://gminadlaciebie.pl/"
                />
                <meta name="twitter:title" content="Gmina dla Ciebie" />
                <meta
                    name="twitter:description"
                    content="Gmina dla Ciebie | Bojanów"
                />
                <meta name="twitter:image" content={shareImage} />
                <link rel="canonical" href="https://gminadlaciebie.pl" />
                <link
                    rel="stylesheet"
                    href="https://use.typekit.net/quj7rva.css"
                ></link>
            </Helmet>
            {children}
            <Socials />
            <script
                async
                data-id="101447186"
                src="//static.getclicky.com/js"
            ></script>
        </>
    );
};

export default Layout;
